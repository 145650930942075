.linksComponent{
    display: flex;
    background: black;
    > * {
        margin: auto;
        text-decoration: none;
        color: whitesmoke;
        padding: 5px;
        font-weight: lighter;
    }
}