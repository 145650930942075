.allUsers{

    h1{
        text-align: center;
        font-weight: normal;
        padding: 40px;
        background: #303030;
        color: white;
    }

    .contractor{
        span{
            display: inline-block;
            width: calc(100%/3);
            padding: 2px;
            color: black;
        }
    }

    .contractor:nth-child(odd){
        background: rgb(225, 231, 238);
    }

    .titles{
        display: flex;
        span{
            width: 33%;
            flex: 1;
            font-weight: bold;
        }
    }

    input{
        width: 50vw; 
        padding: 5px; 
        margin: 10px auto;
        display: block;
    }

}

@media(min-width: 600px){
    .allUsers{
        .contractor{
            span{
                padding: 2px 7%;
            }
        }
        .titles{
            span{
                padding: 2px 7%;
            }
        }
    }
}