.invoiceList{
    h1{
    text-align: center;
    font-weight: normal;
    padding: 40px;
    background: #303030;
    color: white;
    }

    .rowhold{
        width: 100%; 
        height: 20px;
        background: red;
        text-decoration: none;
        color: black;
        &:nth-child(odd){
            > div{
                background: whitesmoke;
            }
        }
    }

    > div, .row{
        display: flex; 
        width: 100%;
        padding: 1px 10px; 
        
        span{
            flex: 1;

            &:nth-child(2){
                text-align: right;
            }
        }
    }


}


@media(min-width: 600px){
    .invoiceList{
        .row{
            margin: auto;
            width: calc(70vw);
            padding: 0 !important;
        }

        > div{
            padding: 0;
            width: 70vw;
            margin:auto;
        }
    
        span{
            max-width: 35vw;
        }

    }
}