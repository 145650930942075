.adminInvoice{

    padding-bottom: 50px;
    h1{
        text-align: center;
        font-weight: normal;
        padding: 40px;
        background: #303030;
        color: white;
    }


    h3{
        margin-left: 10%;
    }
    .workContainer{

        position: relative;
        margin: 10px auto !important; 
        background: whitesmoke;

        .containerLabel{
            position: absolute;
            left: 10px;
            top: 0px;
        }

        
        display: flex; 
        flex-direction: column;
        width: 80%;
        margin: auto;
        padding: 10px;

        label{
            margin-top: 10px;
        }

        .field{
            display: flex;
            flex-direction: column;
        }
    }

    .updatedbanner{
        width: 100vw;
        background: rgb(6, 92, 49); 
        padding: 10px; 
        display: block;
        text-align: center;
        color: whitesmoke;
    }

    > button{
        padding: 5px 10px; 
        background: black;
        color: white;
        display: block; 
        margin: auto;
        margin-top: 10px;
        margin-right: 10%; 
        border: 0;
    }

    p{
        margin-left: 10vw;
    }

    .special{
        margin: 0; 
        margin-left: 10vw;
    }

}

@media(min-width: 600px){
    .adminInvoice{

        .field{
            margin: 10px;
        }
        .workContainer{
            width: 800px;
            flex-direction: row;
            > .field{
                margin: auto;
            };
            > button{
                line-height: 25px;
                width: 80px;
                margin-top: auto;
                margin-bottom: 2px;
            }
        }

        h3{
            margin-left: calc(50vw - 400px);
        }

       
    }
}