@keyframes loginentry{
    from{
        transform: translateY(-100%) scale(0.1);
    }
    to{
        transform: none;
    }
}

.loginModal{
    animation: loginentry 0.3s ease;
    position: fixed; 
    width: 90vw; 
    // height: 60vh; 
    top: 50px; 
    left: 5vw;
    background: rgba(0, 0, 0, 0.95);
    display: flex; 
    flex-direction: column;
    padding: 30px;

    label{
        color: white;
    }

    input[type=checkbox], label{
        display: inline;
    }

    h1{
        color: white;
        text-align: center;
        padding: 20px; 
        font-weight: normal;
    }

    input{
        margin: 20px 10px; 
        padding: 5px;
    }

    .button{
        background: #3B4159;
        border: 0; 
        padding: 15px 10px;
        color: white;
        width: 150px;
        display: inline-block;
        margin: 50px auto;
        font-weight: lighter;
        text-decoration: none;
        text-align: center;

        &:disabled{
            color: grey;
        }
    }

    p{
        color:white;
        font-weight: lighter;
    }

}

.loginFailed{
    @keyframes failed{
        0%{
            transform: translateX(-20px);
        }
        50%{
            transform: translateX(20px); 
        }
        100%{
            transform: none;
        }
    }

    animation: failed 0.2s; 
    animation-iteration-count: 4;
}

@media(min-width: 600px){
    .loginModal{
        max-width: 500px;
        left: calc(50vw - 250px);
    }
}