.contractorView{
    h1{
        text-align: center;
        font-weight: normal;
        padding: 40px;
        background: #303030;
        color: white;
    }

    p, h4{
        text-align: center;
        margin: auto;
    }

    div{
        input, label{
            display: block;
            width: 80%;
            margin: auto;
            border: 0;
        }

        input{
            background: white;
            padding: 5px;
            border: 1px solid rgb(141, 141, 141);
            border-radius: 2px;
        }
        padding: 5px;
    }

    button{
        padding: 10px; 
        color: white;
        background: black;
        font-weight: normal;
        margin: auto;
        display: block;
        border: 0;
    }
    .viewInvoices{
        display: block;
        margin: auto;
       background: black;
        color: white; 
        text-decoration: none;
        width: 100px;
        font-weight: lighter;
        text-align: center;
        padding: 5px;
    }

    .fixedContainer{
        margin: auto;
        background:red;
    }
}

@media(min-width: 600px){
    .contractorView{
        label{
            max-width: 500px;
        }
        button{
            margin: 5px auto;
        }
    }
}