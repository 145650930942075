.createUser{
    display: flex; 
    flex-direction: column;

    h1{
        text-align: center;
        font-weight: normal;
        padding: 40px;
        background: #303030;
        color: white;
    }

    input, textarea{
        padding: 5px; 
        width: 80%;
        margin: 10px auto;
    }

    textarea{
        max-width: 80%; 
        max-height: 150px;
    }

    button{
        width: 80%;
        margin: auto;
        padding: 10px;
        border: 0; 
        background: #3B4159;
        color: white;
    }
}

@media(min-width: 600px){
    input, textarea, button{
        max-width: 500px !important;
    }
}