.admin{
    display: flex;
    flex-direction: column;

    text-align: center;

    h1{
        font-weight: normal;
        padding: 40px;
        background: #303030;
        color: white;
    }
    .button{
        background: #303030;
        border: 0; 
        padding: 15px 10px;
        color: white;
        width: 150px;
        display: inline-block;
        margin: 20px auto;
        font-weight: lighter;
        text-decoration: none;
        text-align: center;
    }
}