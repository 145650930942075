.home {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;

    header {
        background: #303030;
        display: flex;
        position: relative;

        img {
            margin: auto;
            width: 200px;
            padding: 40px;
            filter: invert(1);
        }
        > div {
            position: absolute;
            width: 100%;
            height: 10px;
            background: linear-gradient(90deg, rgba(255, 217, 0, 0.027), #3b4159, rgba(255, 217, 0, 0.027));
            bottom: 0;
        }
    }

    button {
        background: #3b4159;
        border: 0;
        padding: 15px 10px;
        color: white;
        width: 150px;
        display: inline-block;
        margin: 50px auto;
        font-weight: lighter;
    }

    span {
        font-size: 10px;
        padding: 3px;
        background: #303030;
        color: white;
    }
}
