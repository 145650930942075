.contractor{

    h1{
        text-align: center;
        font-weight: normal;
        padding: 40px;
        background: #303030;
        color: white;
    }
    
    label{
        display: block;
    }

    .fixedContainer{
        margin: auto;
        width: 80%;
        padding: 20px;
    }

    .workContainer{
        width: 90%;
        margin: auto;
        padding: 5% 10%;
         position: relative; 
         background:whitesmoke; 
         margin: 10px auto;

        
        >div {

            > input{
                display: block;
            }

            .datepicker{
                display: flex;
            }

            > button{
                background:black;
                display: block;
                margin-top: 20px;
                padding: 5px 10px; 
                border: 0;
                color: white;
            }
            display: flex;
            flex-direction: column;
    }


    }
     h3{
         padding: 10px;
         padding-top: 30px;
         text-align: left;
         margin-left: 50px;
     }

     > div > button{
         display: block;
         margin: 10px auto; 
         margin-right: 15%;
         background: rgb(48, 58, 70); 
         color: white; 
         padding: 5px 10px; 
        bordeR: 0;
        &:disabled{
            display: none;
        }
     }

     .totals{
        width: 90%;
        margin: auto;

        h3{
            margin-left: 0;
            padding: 0;
        }
     }

     .error{
         padding: 10px; 
         border: 2px solid red;
         background: pink; 
     }

     .fixedData{
        width: 80%;
        margin: auto;
     }

     .link{
         margin: auto; 
         text-align: center;
         color: black;
         display: block;
     }

     .invoiceNumber{
         margin: auto;
         width: 80%;
     }


     .submit{
         margin: 0;
         margin-left: 5vw;
         margin-bottom: 100px;
     }
}

@media(min-width: 600px){
    .contractor{
        .workContainer{
            width: 100%;
            display: flex; 
            flex-direction: row;


        > span{
            position: absolute; 
            left: 10vw; 
            top: 25px;
        }

            >div{
                padding: 10px;
            }

            >button{
                height: 30px;
                padding: 0 10px;
                margin: 0 auto; 
                margin-top: 32px;
            }
        }
    }
}