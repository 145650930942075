.LandingPage{
    background: rgba(230, 230, 230, 0.486);
    padding-bottom: 10vw;
    .hero{
        padding: 40px;
        position: relative;
        height: 350px;
        display: flex;
        flex-direction: column;
        text-align: center;
        background: rgba(0, 0, 0, 0.705); 
        justify-content: center;

        img{
            position: absolute; 
            top: 20px; 
            left: calc(50vw - 45px);
            width: 90px; 
            margin: auto;
            margin-bottom: 10px;
            padding: 0px; 
            filter: invert(1);
        }

        color: white;

        h2{
            margin-top: 70px;
            font-weight: normal;
            font-size: 20px;
        }

        p{
            font-weight: lighter;
            padding: 10px;

            a{
                padding: 2px 10px; 
                color: black;
                background: lightblue;
                text-decoration: none;
                margin-left: 2px;
            }
        }
        
        &::before{
            position: absolute;
            left: 0; 
            top: 0;
            content: ''; 
            height: 100%;
            width: 100vw; 
            background: url(../images/bannerimage.png);
            background-size: cover;
            background-position: center;
            z-index: -1;
        }

    }
    .container{
        display: flex;
        flex-direction: column;
    }

    .links{
        display: inline-flex; 
        flex-direction: column;
        padding: 50px;
        background: whitesmoke;
        width: 80%; 
        margin: 20px auto;
        box-shadow: 2px 2px 15px rgba(189, 189, 189, 0.527);
        position: relative;

        h3{
            text-align: center;
            font-weight: normal;
            font-size: 25px;
        }

        p{
            margin: auto; 
            font-weight: lighter;
            text-align: center;
        }

        img{
            width: 70%; 
            padding: 20px;
            margin: auto;
            z-index: 0;
            filter: invert(0.2);
        }
    }

   .links{
       ul{
            > a{
            display: block;
            padding: 10px;
            font-weight: normal;
            text-decoration: none;
            color: lightblue;
            text-align: center;
            }

            p{
                padding-top: 10px;
                text-align: center;

                b{
                    font-weight: 400;
                }
            }
       }
   }

   .invoiceModal{
       position: fixed; 
       top: 0; 
       left: 0; 
       width: 100vw;
       height: 100vh;
       background: rgba(0, 0, 0, 0.8);
       z-index: 50;
       display: flex;
       text-align: center;

       >div{
           background:white;
           width: 80vw; 
           height: 200px;
           margin: auto;
           padding: 50px;
           button{
               display: block;
               margin: auto;
               background: black;
               color: white;
               padding: 10px;
               font-weight: lighter;
               border: 0;
               margin-top: 20px;
           }
       }
   }
}
    
@media(min-width: 600px){
    .hero{
        height: 40vh !important;
        img{
            width: 12.5vh !important;
            left: calc(50vw - 6.25vh) !important;
        }
        h2{
            font-size: 30px;
        }
    }
    .container{
        padding-top: 50px;
        display: block !important;
        justify-content: center;
        align-items: center;
        text-align: center;
        max-width: 1200px;
        margin: auto;
        position: relative;
        height: auto;

        .links, .contact{
            display: inline-block !important;
            height: 400px !important;
            width: 300px !important; 
            margin: 10px !important;
            vertical-align: top;
            img{
                width: 40%;
            }
        }

        // > div{
        //     height: 100% !important;
        //     margin: auto 10px !important;
        //     img{
        //         width: 40% !important;
        //     }
        // }
    }
}