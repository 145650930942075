.changePassword{
    h1{
        font-weight: normal;
        padding: 40px;
        background: #303030;
        color: white;
        text-align: center;
    }

    span{
        display: block;
        text-align: center;
        background: steelblue;
        color: white;
        padding: 5px;
    }

    >div{

        display: flex;
        flex-direction: column;
        margin: auto;
        width: 80%;
        padding: 20px;

        button{
            background: black;
            color: white;
            bordeR: 0; 
            margin: 10px 0; 
            padding: 5px 10px;
        }

    }

}